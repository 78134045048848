/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { IntegrationError } from './IntegrationError';
import {
    instanceOfIntegrationError,
    IntegrationErrorFromJSON,
    IntegrationErrorFromJSONTyped,
    IntegrationErrorToJSON,
} from './IntegrationError';
import type { UnprocessableNumberError } from './UnprocessableNumberError';
import {
    instanceOfUnprocessableNumberError,
    UnprocessableNumberErrorFromJSON,
    UnprocessableNumberErrorFromJSONTyped,
    UnprocessableNumberErrorToJSON,
} from './UnprocessableNumberError';

/**
 * @type PostUsersV1Phone503Response
 * 
 * @export
 */
export type PostUsersV1Phone503Response = IntegrationError | UnprocessableNumberError;

export function PostUsersV1Phone503ResponseFromJSON(json: any): PostUsersV1Phone503Response {
    return PostUsersV1Phone503ResponseFromJSONTyped(json, false);
}

export function PostUsersV1Phone503ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUsersV1Phone503Response {
    if (json == null) {
        return json;
    }
    return IntegrationErrorFromJSONTyped(json, true) || UnprocessableNumberErrorFromJSONTyped(json, true);
}

export function PostUsersV1Phone503ResponseToJSON(value?: PostUsersV1Phone503Response | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfIntegrationError(value)) {
        return IntegrationErrorToJSON(value as IntegrationError);
    }
    if (instanceOfUnprocessableNumberError(value)) {
        return UnprocessableNumberErrorToJSON(value as UnprocessableNumberError);
    }

    return {};
}

