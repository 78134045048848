import { ErrorCode, ModelError } from '@/shared/api/codegen/demuzo';
import {
  ApiClientError,
  ApiClientErrorCodeEnum,
  ApiClientErrorPayload,
  ApiServerErrorResolverMap,
  ClientError,
} from '@/shared/api/types';

export function isServerApiError(value: any): value is ModelError {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  if (!('code' in value)) return false;
  if (!('message' in value)) return false;
  return [
    ErrorCode.VALIDATION_ERROR,
    ErrorCode.NOT_FOUND,
    ErrorCode.USER_BLOCKED,
    ErrorCode.USER_NOT_FOUND,
    ErrorCode.CAMPAIGN_NOT_IN_PROGRESS,
    ErrorCode.NOT_ENOUGH_CAMPAIGN_SHARES,
    ErrorCode.INSUFFICIENT_BALANCE,
    ErrorCode.AMOUNT_LESS_OR_EQUAL_THAN_0,
    ErrorCode.KYC_STATUS_IS_NOT_APPROVED,
    ErrorCode.TEMPORARY_FORBIDDEN,
  ].includes(value.code);
}

export const SERVER_AUTH_ERRORS: ErrorCode[] = [
  ErrorCode.TOKEN_INVALID,
  ErrorCode.TOKEN_EXPIRED,
  ErrorCode.TOKEN_NOT_FOUND,
];
export const SERVER_BUSINESS_ERRORS: ErrorCode[] = [
  ErrorCode.VALIDATION_ERROR,
  ErrorCode.NOT_FOUND,
  ErrorCode.USER_BLOCKED,
  ErrorCode.USER_NOT_FOUND,
  ErrorCode.CAMPAIGN_NOT_IN_PROGRESS,
  ErrorCode.TEMPORARY_FORBIDDEN,
  ErrorCode.NOT_ENOUGH_CAMPAIGN_SHARES,
  ErrorCode.NOT_FOUND,
  ErrorCode.INSUFFICIENT_BALANCE,
];

export function isBusinessApiError(value: any) {
  if (!isServerApiError(value)) {
    return false;
  }

  return SERVER_BUSINESS_ERRORS.includes(value.code);
}

export function isAuthApiError(value: any) {
  return SERVER_AUTH_ERRORS.includes(value.code);
}

export function makeApiClientError<T extends ApiClientErrorCodeEnum>(
  code: T,
  message?: string,
  payload?: ApiClientErrorPayload,
): ApiClientError<T> {
  return {
    code,
    message,
    payload,
  };
}

export const matchErrorsWithResolvers = <T extends ClientError>(error: T) => {
  return (errorResolverMap: ApiServerErrorResolverMap<T>) => {
    const errorResolver = errorResolverMap[error.code as T['code']];
    if (errorResolver === undefined) {
      return errorResolverMap.default(error);
    }
    errorResolver(error as any);
  };
};
