/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Допустимые медиа типы файлов
 * @export
 */
export const MimeTypeEnum = {
    audio_mpeg: 'audio/mpeg',
    video_mp4: 'video/mp4',
    image_jpeg: 'image/jpeg',
    image_png: 'image/png',
    image_webp: 'image/webp'
} as const;
export type MimeTypeEnum = typeof MimeTypeEnum[keyof typeof MimeTypeEnum];


export function instanceOfMimeTypeEnum(value: any): boolean {
    return Object.values(MimeTypeEnum).includes(value);
}

export function MimeTypeEnumFromJSON(json: any): MimeTypeEnum {
    return MimeTypeEnumFromJSONTyped(json, false);
}

export function MimeTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MimeTypeEnum {
    return json as MimeTypeEnum;
}

export function MimeTypeEnumToJSON(value?: MimeTypeEnum | null): any {
    return value as any;
}

