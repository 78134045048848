/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Статусы проверки KYC
 * @export
 */
export const KYCStatus = {
    not_started: 'not_started',
    documents_requested: 'documents_requested',
    pending: 'pending',
    processing: 'processing',
    approved: 'approved',
    resubmission_requested: 'resubmission_requested',
    rejected: 'rejected',
    requires_action: 'requires_action',
    profile_incomplete: 'profile_incomplete'
} as const;
export type KYCStatus = typeof KYCStatus[keyof typeof KYCStatus];


export function instanceOfKYCStatus(value: any): boolean {
    return Object.values(KYCStatus).includes(value);
}

export function KYCStatusFromJSON(json: any): KYCStatus {
    return KYCStatusFromJSONTyped(json, false);
}

export function KYCStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KYCStatus {
    return json as KYCStatus;
}

export function KYCStatusToJSON(value?: KYCStatus | null): any {
    return value as any;
}

